<template>
    <v-card flat  class="pa-2" style="height: 100%; width: 100%;">
        <v-card-text>
            <!-- <v-img src="/images(2).jpeg" max-height="200" max-width="200"></v-img> -->
            <!-- <v-img src="/images.jpeg" max-height="200" max-width="200"></v-img> -->
            <v-img src="https://www.animatedimages.org/data/media/1104/animated-construction-worker-image-0046.gif" height="350" width="350" class="ma-auto"></v-img>
           <v-row class="mt-3">
            <span class="text-h2 shade4--text font-weight-bold mx-auto">COMING ...</span>
           </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "Under Construction",
    data() {
        return {
            
        }
    },
  
}
</script>

    