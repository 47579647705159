
export const hobbies = [
  "Football",
  "Basketball",
  "Tennis",
  "Table tennis",
  "Soccer",
  "Kayaking",
  "Bobsleigh",
  "Canoeing",
  "Rafting",
  "Skibobbing",
  "Surfing",
  "Snorkeling",
  "Swimming",
  "diving",
  "Paddleboarding",
  "Rowing",
  "Archery",
  "Artistic gymnastics",
  "Bodybuilding",
  "Baton twirling",
  "Aerobics",
  "Aikido",
  "Boxing",
  "Cycling",
  "Discus throw",
  "Equestrianism",
  "Fencing",
  "Figure skating",
  "Horse racing",
  "Judo",
  "Karate",
  "Kendo",
  "Kickboxing",
  "Kung fu",
  "Marathon",
  "Martial arts",
  "Muay Thai",
  "Pole vault",
  "Powerlifting",
  "Racewalking",
  "Sumo",
  "Sword-fighting",
  "Trail running",
  "Trampolining",
  "Tumbling",
  "Walking",
  "Weightlifting",
  "Wrestling",
  "Baseball",
  "Badminton",
  "Bowling",
  "Cricket",
  "Curling",
  "Dodgeball",
  "Golf",
  "Handball",
  "Hockey",
  "Hurling",
  "Lacrosse",
  "Paddle",
  "Polo",
  "Racquetball",
  "Rinkball",
  "Rounders",
  "Rugby",
  "Softball",
  "Volleyball",
  "Board games",
  "Chess",
  "Gambling",
  "Golf",
  "Card Playing",
  "Investing",
  "Martial arts",
  "Yoga",
  "Taichi",
  "Meditation",
  "Mountain biking",
  "Parachuting",
  "Running/Jogging",
  "Singing",
  "Acting/Drama",
  "Cooking & Dining",
  "Crafts",
  "Dancing",
  "Musical instrument",
  "Origami",
  "Painting",
  "Photography",
  "Pottery",
  "Reading",
  "Volunteering",
  "Childcare",
  "Languages",
  "Jigsaw puzzles",
  "Archery",
  "Backgammon",
  "Car restoration",
  "Landscaping",
  "Lego building",
  "Robotics",
  "Skydiving",
  "Cricket",
  "Knitting",
  "Skiing",
  "Animals and pets",
  "Club leadership",
  "Paragliding",
  "Rock climbing",
  "Socialising",
  "Coding/Programming",
  "Drawing",
  "Fishing",
  "Hunting",
  "Snooker/Pool",
  "Video games",
  "Video production",
  "Cycling",
  "Hiking",
  "Model building",
  "Papermaking",
  "Squash",
  "Woodworking",
  "Amateur radio",
  "Blogging",
  "Calligraphy",
  "Creative writing",
  "Crossword puzzles",
  "Surfing",
  "Travelling",
  "Amateur astronomy",
  "Collecting",
  "Gardening",
  "Genealogy",
  "Marathon running",
  "Recycling",
  "Sailing",
  "Sculpture",
  "Shopping",
  "Youtuber",
  "Karaoke",
  "Night Life",
  "Psychics",
  "Dining Outside"
];

export const religions = [
  "Eastern Orthodoxy",
  "Roman Catholicism",
  "Protestantism",
  "Taoism",
  "Shinto",
  "Buddhism",
  "Hinduism",
  "Islam",
  "Judaism",
  "Baha'i",
  "Jainism",
  "Sikhism",
  "Zoroastrianism",
  "None"
];

export const favoriteFood = [
  "Mexican",
  "Carribean",
  "Middle Eastern",
  "Turkish",
  "Himalayan/Nepalese",
  "Peruvian",
  "Mediterranean",
  "Filipino",
  "New Canadian",
  "Portuguese",
  "Cajun/Creole",
  "Fish & Chips",
  "Soul Food",
  "Persian/Iranian",
  "Chicken Wings",
  "Burgers",
  "Kebab",
  "Halal",
  "Dim Sum",
  "Bistros",
  "Vegetarian",
  "German",
  "Malaysian",
  "Chinese",
  "Japanese & Sushi",
  "Thai",
  "Steak",
  "Korean",
  "Greek",
  "Vietnamese",
  "Italian",
  "Pizza",
  "Hotpot",
  "Coffee Shop",
  "Tea House",
  "French",
  "American (Traditional)",
  "Spanish"
];


