import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import store from "./store/store.js";
import vuetify from "./plugins/vuetify";
import PortalVue from "portal-vue";
import  moment  from "moment"; 
import { VueMasonryPlugin } from 'vue-masonry'
import "vue2-animate/dist/vue2-animate.min.css";
import Chat from 'vue-beautiful-chat'
Vue.use(Chat)

import {
  ApolloClient
} from "apollo-client";
import {
  InMemoryCache
} from "apollo-cache-inmemory";
import {
  createUploadLink
} from "apollo-upload-client";
import {
  onError
} from "apollo-link-error";
import {
  ApolloLink,
  Observable
} from "apollo-link";
import {
  WebSocketLink
} from "apollo-link-ws";
import {
  getMainDefinition
} from "apollo-utilities";
import VueApollo from "vue-apollo";
import Alert from "./components/Alert";
import VueQrcode from "@chenfengyuan/vue-qrcode";
// import CKEditor from '@ckeditor/ckeditor5-vue'
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import gql from "graphql-tag"

// FingerPrint to do the initial check
(async () => {
  // We recommend to call `load` at application startup.
  const fp = await FingerprintJS.load();

  // The FingerprintJS agent is ready.
  // Get a visitor identifier when you'd like to.
  const result = await fp.get();

  // This is the visitor identifier:
  const visitorId = result.visitorId;
  // console.log(visitorId);
  store.commit("setFingerPrint", visitorId);

  store.dispatch("checkSavedFingerPrint", {
    fingerPrint: visitorId
  })
  // const fingerPrint = store.getters.fingerPrint;
  // console.log(fingerPrint);
})();

// export const eventBus_signout = new Vue()
export const eventBus_profile = new Vue();
export const eventBus_vendorParlour = new Vue();
export const eventBus_editElement = new Vue();
export const eventBus_addPage = new Vue();
export const eventBus_preview = new Vue();
export const eventBus_saveSketch = new Vue();
export const eventBus_appendHook = new Vue();
export const eventBus_saveTemplate = new Vue();
export const eventBus_saveFlyer = new Vue();
export const eventBus_stashFlyer = new Vue()
export const eventBus_feedPet = new Vue()
export const eventBus_actionPanel = new Vue()
export const eventBus_toggleMapPosition = new Vue()
export const eventBus_pickVendor = new Vue()
export const eventBus_searchVendor = new Vue()
export const eventBus_sortPromotionEvents = new Vue()
export const eventBus_crackEgg = new Vue()

// Register Global Component
Vue.use(VueApollo);
Vue.use(PortalVue);
Vue.use(VueMasonryPlugin)
// Vue.use(CKEditor)

//component
Vue.component("form-alert", Alert);
Vue.component(VueQrcode.name, VueQrcode);

//Directives
Vue.directive('event-type-photo', {
  bind(el, binding, vnode) {
    switch (binding.value) {
      case "On_Sale":
        el.src = "/static/sales_images__1_-removebg-preview.png";
    }
    
  }
})


//Filters
Vue.filter('convert-date', value => {
  return moment.utc(new Number(value)).format("YYYY-MM-DD")
})

Vue.filter('convert-customer-rating-time', value => {
  return moment.utc(new Number(value)).format("MMMM Do YYYY, h:mm:ss a")
})

Vue.filter('ellipsis-order-no', value => {
  return value.length > 16 ? value.substr(0, 12) + '...' + value.substr(-4) : value
})

Vue.filter('ellipsis-description', value => {
  return value.length > 20 ? value.substr(0, 15) + '...' + value.substr(-4) : value
})

Vue.filter('format-currency-amount', value => {
  return new Intl.NumberFormat('en-US', { style: 'currency', 
  currency: 'USD', 
  maximumFractionDigits: 2, 
  minimumFractionDigits: 2,
  // roundingIncrement: 5,
  trailingZeroDisplay:"auto"  }).format(value)
})

Vue.filter('format-amount', value => {
  // if(value==0) return 0
  return new Intl.NumberFormat('en-US', {  
    maximumFractionDigits: 2, 
    minimumFractionDigits: 2,
  // roundingIncrement: 0  
}).format(value)
})
Vue.filter('format-int-amount', value => {
  // if(value==0) return 0
  return new Intl.NumberFormat('en-US', {  
    // maximumFractionDigits: 0, 
    // minimumFractionDigits: 0,
  // roundingIncrement: 0  
}).format(value)
})
// console.log("mainjs is running");

// Set up request
const request = (operation) => {
  // if no token with key in the localStorage, add it
  if (!localStorage.token) {
    // console.log("resident");
    localStorage.setItem("token", "");
  }

  if (!localStorage.vendortoken) {
    // console.log("vendor");
    localStorage.setItem("vendortoken", "");
  }

  // operation adds the token to an authorization header that is to be sent to server
  let token = localStorage.token ?
    localStorage.getItem("token") :
    localStorage.getItem("vendortoken");
  // console.log(token);
  // let decodedToken = jwt_decode(token);
  // console.log(token);
  // console.log("Decoded Token", decodedToken);
  // let currentDate = new Date();

  // JWT exp is in seconds
  // if (decodedToken.exp * 1000 < currentDate.getTime()) {
  //   operation.setContext({
  //     headers: {
  //       authorization: token,
  //     },
  //   });
  //   store.commit(
  //     "setAuthError",
  //     "Your Token has Expired, Please sign in again"
  //   );
  //   router.replace("/");
  // } else {
  //   console.log("Valid token");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  // }
};

// set up the request handlers for the http clients
const requestLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
      .then((oper) => {
        request(oper);
      })
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));
    return () => {
      if (handle) handle.unsubscribe();
    };
  });
});

// Set up websocket link for subscriptions
const wsLink = ApolloLink.from([
  onError(({
    graphQLErrors,
    networkError
  }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log(
          `[GraphQL error]: Message: ${err.message},Location: ${err.locations}, Path: ${err.path}`
        );
        if (err.name === "AuthenticationError") {
          console.log(err);
          // set auth error in state
          store.commit("setAuthError", err);
          // Sign user out
          if (localStorage.token) {
            store.dispatch("signoutResident");
          }
          if (localStorage.vendortoken) {
            store.dispatch("signoutVendor");
          }
        }
      }
    }
    if (networkError) {
      console.log("[networkError]", networkError);
    }
  }),
  

  requestLink,

  new WebSocketLink({
    uri: `wss://boundary-faf8da99353c.herokuapp.com/graphql`,
    // uri: `ws://localhost:4000/graphql`,
    options: {
      reconnect: true,
      connectionParams: () => {
        if (localStorage.token) {
          const token = localStorage.getItem("token");
          return {
            Authorization: `Bearer ${token}`,
          };
        }
        if (localStorage.vendortoken) {
          const token = localStorage.getItem("vendortoken");
          return {
            Authorization: `Bearer ${token}`,
          };
        }
      },
    },
  }),
]);

// HTTP link for queries and mutations
const httpLink = ApolloLink.from([
  onError(({
    graphQLErrors,
    networkError
  }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.dir(err);
        if (err.name === "AuthenticationError") {
          console.log(err.name);
          // set auth error in state
          store.commit("setAuthError", err);
          // Sign user out
          if (localStorage.token) {
            store.dispatch("signoutResident");
          }
          if (localStorage.vendortoken) {
            store.dispatch("signoutVendor");
          }
          store.dispatch("getEventCategory");
          store.dispatch("getPromotionEvents")
        }
        if (err.name === "GraphQLError") {
          console.log(err.name);
          store.commit("setError", err);
          // set auth error in state
          // store.commit("setAuthError", err)
          // Sign user out
          // store.dispatch("signoutUser")
        }
      }
    }
    if (networkError) {
      console.log("[networkError", networkError);
    }
  }),

  requestLink,

  // Create file upload link
  new createUploadLink({
    uri: `https://boundary-faf8da99353c.herokuapp.com/graphql`,
    // uri: `http://localhost:4000/graphql`,
    credentials: "include",
  }),
]);

// Link to direct ws and http traffic to the correct place
const link = ApolloLink.split(
  ({
    query
  }) => {
    const {
      kind,
      operation
    } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

// Set up apolloClient and apolloProvider
export const defaultClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
const apolloProvider = new VueApollo({
  defaultClient,
});

Vue.config.productionTip = false;

new Vue({
  apolloProvider,
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    // console.log("vue starting")
    this.$store.dispatch("getCurrentResident");
    this.$store.dispatch("getCurrentVendor");
    this.$store.dispatch("getPets");
    this.$store.dispatch("getActiveFlyer");
    this.$store.dispatch("getEventCategory");
    this.$store.dispatch("getPromotionEvents")
    this.$store.dispatch("getAllGuilds");
    this.$store.dispatch("getAllGuildDeals");
    this.$store.dispatch("getMetroSpec");
    this.$store.dispatch("getCityHall")
    this.$store.dispatch("getProductCategory");
    this.$store.dispatch("getServiceCategory");
    this.$store.dispatch("getRestaurantCategory");
    this.$store.dispatch("getVendorList");
    this.$store.dispatch("getNews")
  },
}).$mount("#app");