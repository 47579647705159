import state from "./state";

const getters = {
  activeFlyerList: (state) => state.activeFlyerList,
  allItemsCatalog: (state) => state.allItemsCatalog,
  allGuildDeals: (state) => state.allGuildDeals,
  authError: (state) => state.authError,
  cityHall: (state) => state.cityHall,
  clientPageView: (state) => state.clientPageView,
  clientPreviewLoading: (state) => state.clientPreviewLoading,
  commitGuildDealLoading: (state) => state.commitGuildDealLoading,
  customerRatings: (state) => state.customerRatings,
  demandSearch: (state) => state.demandSearch,
  error: (state) => state.error,
  eventCategory: (state) => state.eventCategory,
  flyerFormatType: (state) => state.flyerFormatType,
  fingerPrint: (state) => state.fingerPrint,
  fingerPrintIsSave: (state) => state.fingerPrintIsSave,
  footerHeight: (state) => state.footerHeight,
  gamePropList: (state) => state.gamePropList,
  gameSubstituteList: (state) => state.gameSubstituteList,
  guilds: (state) => state.guilds,
  guildChatMessages: (state) => state.guildChatMessages,
  guildDeals: (state) => state.guildDeals,
  guildDealsStatus: (state) => state.guildDealsStatus,
  guildLogos: (state) => state.guildLogos,
  inDesign: (state) => state.inDesign,
  isPromotionEventShowed: (state) => state.isPromotionEventShowed,
  isSearchBtnDisabled: (state) => state.isSearchBtnDisabled,
  itemCatalogUpdated: (state) => state.itemCatalogUpdated,
  itemCatalogSaved: (state) => state.itemCatalogSaved,
  itemCatalogLoading: (state) => state.itemCatalogLoading,
  loading: (state) => state.loading,
  markerList: (state) => state.markerList,
  mergeIsDone: (state) => state.mergeIsDone,
  metroSpec: (state) => state.metroSpec,
  navbarHeight: (state) => state.navbarHeight,
  news: (state) => state.news,
  pagePreview: (state) => state.pagePreview,
  pagePreview_C: (state) => state.pagePreview_C,
  pets: (state) => state.pets,
  placeOrderLoading: (state) => state.placeOrderLoading,
  promotionEvents: (state) => state.promotionEvents,
  promotionEventsProcessed: (state) => state.promotionEventsProcessed,
  productsCategories: (state) => state.productsCategories,
  resident: (state) => state.resident,
  residentOrders: state => state.residentOrders,
  restaurantCategories: (state) => state.restaurantCategories,
  rewardItems: (state) => state.rewardItems,
  savedFlyer: (state) => state.savedFlyer,
  savedFlyerList: (state) => state.savedFlyerList,
  saveShoppingCartLoading: (state) => state.saveShoppingCartLoading,
  searchCouponLoading: (state) => state.searchCouponLoading,
  servicesCategories: (state) => state.servicesCategories,
  selectedSketch: (state) => state.selectedSketch,
  selectedSketch_C: (state) => state.selectedSketch_C,
  selectedTemplate: (state) => state.selectedTemplate,
  selectedTemplate_C: (state) => state.selectedTemplate_C,
  shoppingCart: (state) => state.shoppingCart,
  simpleFlyer: (state) => state.simpleFlyer,
  singleItemRating: (state) => state.singleItemRating,
  sketchList: (state) => state.sketchList,
  soughtDeals: (state) => state.soughtDeals,
  targetDistributeNumber: (state) => state.targetDistributeNumber,
  templateList: (state) => state.templateList,
  templateIsSaved: (state) => state.templateIsSaved,
  vendor: (state) => state.vendor,
  vendorFlyers: (state) => state.vendorFlyers,
  vendorHomeLoading: (state) => state.vendorHomeLoading,
  vendorList: (state) => state.vendorList,
  vendorProfileLoading: (state) => state.vendorProfileLoading,
  vendorOrders: state => state.vendorOrders,
  vendorSalesInfo: state => state.vendorSalesInfo,
  vendorSettlementRecords: state => state.vendorSettlementRecords,
  vendorPromotionEvents: (state) => state.vendorPromotionEvents,
  vendorInterface: (state) => state.vendorInterface,
  viewPortDimension: (state) => state.viewPortDimension
};

export default getters;
