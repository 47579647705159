const state = {
  activeFlyerList: null,
  allItemsCatalog: null,
  allGuildDeals: [],
  authError: null,
  cityHall: null,
  clientPageView: null,
  clientPreviewLoading: false,
  commitGuildDealLoading: false,
  customerRatings: [],
  demandSearch: [],
  error: null,
  eventCategory: [],
  flyerFormatType: null,
  fingerPrint: null,
  fingerPrintIsSaved: null,
  footerHeight: 0,
  gamePropList: [],
  gameSubstituteList: [],
  guilds: [],
  guildChatMessages: [],
  guildDeals: [],
  guildLogos: [],
  guildDealsStatus: [],
  inDesign: false,
  isPromotionEventShowed: true,
  isSearchBtnDisabled: false,
  itemCatalogUpdated: false,
  itemCatalogSaved: null,
  itemCatalogLoading: false,
  loading: false,
  markerList: [],
  mergeIsDone: false,
  metroSpec: null,
  navbarHeight: 0,
  news: [],
  pagePreview: [],
  pagePreview_C: [],
  pets: [],
  placeOrderLoading: false,
  promotionEvents: [],
  promotionEventsProcessed: [],
  productsCategories: [],
  resident: null,
  residentOrders: [],
  restaurantCategories: [],
  rewardItems: null,
  savedFlyer: null,
  savedFlyerList: [],
  saveShoppingCartLoading: false,
  searchCouponLoading: false,
  servicesCategories: [],
  selectedSketch: null,
  selectedSketch_C: null,
  selectedTemplate: null,
  selectedTemplate_C: null,
  shoppingCart: [],
  simpleFlyer: null,
  singleItemRating: null,
  sketchList: [],
  soughtDeals: null, 
  targetDistributeNumber: 0,
  templateList: [],
  templateIsSaved: false,
  vendor: null,
  vendorFlyers: [],
  vendorList: [],
  vendorHomeLoading: false,
  vendorOrders: [],
  vendorProfileLoading: false,
  vendorSalesInfo: null,
  vendorSettlementRecords: [],
  vendorPromotionEvents: [],
  vendorInterface: null,
  viewPortDimension: null
};

export default state;
